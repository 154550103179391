<template>
  <div>
    <excelUploadCard
      urlItem="/custom/upload-household-list"
      :title="$t('Upload household list')"
      :loadbtn="$t('Select household list')"
    />
    <excelUploadCard
      urlItem="/custom/upload-insurance-list"
      :title="$t('Upload policies')"
      :loadbtn="$t('Select policies')"
    />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>